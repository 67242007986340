import React from 'react';
import './App.css';
import MyPage from './findyourcolo.svg'; // Import your SVG

function App() {
  return (
    <div className="App">
      <img src={MyPage} alt="FindMyColo.com is a premier online destination for businesses seeking cutting-edge colocation services across major cities like Chicago, New York, and Dallas. Offering a comprehensive directory of reputable data center providers, our platform simplifies the search for colocation services with personalized recommendations tailored to your unique requirements. With competitive monthly pricing plans starting at $60, we provide scalable bandwidth options, robust DDoS protection, and reliable power specifications to ensure your servers operate optimally. Our up-to-date market knowledge and dedicated support guide you in making an informed decision for your colocation needs. Learn more about our customized colocation solutions and stay tuned for the launch of our new service, FindMyServer.com, designed to further empower your server hosting decisions." className="full-width-svg" />
    </div>
  );
}

export default App;
